<template>

  <b-row >

    <modal-user-profile v-if="showModalFoto" :key="componentKeyModalFoto" :user-id="userId" :token-auth="tokenAuth" />
    <modal-user-banner v-if="showModalBanner" :key="componentKeyModalBanner" :user-id="userId" :token-auth="tokenAuth" />


    <b-col
      sm="12"
      md="12"
      xl="3"
      lg="3"

    >


    <b-link @click="modalBanner" class="camera_icon2"> 

        <b-avatar
        size="25"
        variant="light-primary"
        style="cursor:pointer;">

        <span class="d-flex align-items-center">


          <feather-icon
             icon="CameraIcon"
              size="18"
               
             /> 

        </span>

        </b-avatar>

        </b-link>





      <b-card
        :img-src="banner"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
      >

        <b-row>

          
          <b-col cols="3" sm="2" md="2" xl="4" lg="4" align="center">
            <b-link @click="modalPhoto" class="camera_icon"> 

                <b-avatar
                size="21"
                variant="light-primary"
                style="cursor:pointer;">

                <span class="d-flex align-items-center">


                  <feather-icon
                    icon="CameraIcon"
                      size="16"
                      
                    /> 

                </span>

                </b-avatar>

                </b-link>



                <div class="profile-image p-0">


                <b-avatar
                size="100"
                variant="light"
                :src="photo"
                class="border_profile3"
                />
                </div>

            </b-col>

        


            <b-col cols="9" sm="10" md="10" xl="8" lg="8" >

              <h4 class="font-weight-bolder" style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;margin-top: 10px;margin-bottom: 0px;font-size: 14px;text-align: left !important;">
                <img v-if="isProfileVerificated" title="Perfil verificado" alt="Perfil verificado" class="badgeVerificacion2" src="/img/verificadoFinal.svg" />
                    <img v-else alt="Perfil no verificado" title="Perfil no verificado" class="badgeVerificacion2" src="/img/no_verificadoFinal.svg" />
              {{ displayName }}
                  </h4>

                  <h6
                    v-if="alias"
                    class="text-muted"
                    style="text-align: left;    margin-top: 2px;margin-bottom: 0px;"
                  >
                    @{{ alias }}
                  </h6>

           

              </b-col>


   
      </b-row>
    


      </b-card>

    </b-col>

    <b-col
      sm="12"
      md="12"
      xl="9"
      lg="9"

    >

      <b-tabs
        vertical
        content-class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-1 mt-md-0 sin_padding_profile"
        pills
        nav-wrapper-class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 card2"
        nav-class="nav-left"
        v-model="activoPanel"
      >

        <b-tab   @click="GeneralLoad">

          <template #title>

                    <feather-icon
                                          icon="UserIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />

            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Información personal</span>
            <span class="titulo_menu d-block d-sm-block d-md-none d-lg-none d-xl-none">Mi Perfil</span>
          </template>

          <general
          v-if="ifGeneral"
            :key="componentKeyGeneral"
            :user-id="userId"
            :token-auth="tokenAuth"
          />

        </b-tab>

        <b-tab @click="VerificacionLoad">

          <template #title>
                  <feather-icon
                                          icon="CheckCircleIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />

            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  Verificación de cuenta</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Verificación</span>
          </template>

          <verificacion v-if="ifVerificacion" :key="componentKeyVerificacion" :user-id="userId" :token-auth="tokenAuth" />
        </b-tab>

        <b-tab @click="PasswordLoad">

        <template #title>
                <feather-icon
                                        icon="KeyIcon"
                                        size="15"

                                        class="margin-icon-profile"
                                        />

          <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Actualización de contraseña</span>
          <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Contraseña</span>
        </template>

        <password v-if="ifPassword" :key="componentKeyPassword" :user-id="userId" :token-auth="tokenAuth" />
        </b-tab>

        <b-tab @click="dobleAutenticacionLoad">

          <template #title>
                <feather-icon
                                          icon="LockIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">Código PIN de seguridad</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Código PIN</span>
          </template>

          <doble-autenticacion
            v-if="ifDobleAutenticacion"
            :key="componentKeyDobleAu"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
        </b-tab>

        <b-tab @click="NotificacionesLoad">

          <template #title>
                 <feather-icon
                                          icon="BellIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />

            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">Configuración de notificaciones</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Notificaciones</span>
          </template>

          <notificaciones
            v-if="ifNotificaciones"
            :key="componentKeyNotificaciones"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
        </b-tab>

         <b-tab @click="BotLoad">

          <template #title>
                 <feather-icon
                                          icon="CpuIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />

            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">Configuración del Bot EQCoop</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Bot Eqpay</span>
          </template>

          <bot
            v-if="ifBot"
            :key="componentKeyBot"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
        </b-tab>

        <b-tab @click="BankAccountLoad">

          <!-- title -->
          <template #title>
              <feather-icon
                                          icon="LayersIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Cuenta Bancaria</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Cuenta Bancaria</span>
          </template>

          <cuenta-bancaria
            v-if="ifBankAccount"
            :key="componentKeyBankAccount"
            :user-id="userId"
              :token-auth="tokenAuth"
          />
        </b-tab>

        <b-tab @click="TarjetasLoad">

          <template #title>
              <feather-icon
                                          icon="CreditCardIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">Mis Tarjetas de pago</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Mis Tarjetas</span>
          </template>

          <tarjetas
            v-if="ifTarjeta"
            :key="componentKeyTarjeta"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
          </b-tab>

        <b-tab @click="PresentacionLoad">

          <template #title>
                <feather-icon
                                          icon="InstagramIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  Biografía y redes sociales</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Biografía</span>
          </template>


          <presentacion-redes-sociales v-if="ifPresentacion" :key="componentKeyPresentacion" :user-id="userId"  :token-auth="tokenAuth" />
        </b-tab>

        <b-tab @click="AliasLoad">

          <template #title>
              <feather-icon
                                          icon="AtSignIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Alias de mi perfil</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none"> Alias del perfil</span>
          </template>

          <alias-perfil
            v-if="ifAlias"
            :key="componentKeyAlias"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
        </b-tab>

         <b-tab @click="SesionesLoad">

          <template #title>
              <feather-icon
                icon="SmartphoneIcon"
                size="15"
                class="margin-icon-profile"
                />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block"> Inicios de sesión</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none"> Inicios de sesión</span>
          </template>

          <sesiones
            v-if="ifSesiones"
            :key="componentKeySesiones"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
        </b-tab>


        <b-tab @click="EliminarLoad">

          <template #title>
                      <feather-icon
                                          icon="Trash2Icon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  Eliminar cuenta</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">Eliminar cuenta</span>
          </template>

          <eliminar-cuenta
            v-if="ifEliminar"
            :key="componentKeyEliminar"
            :user-id="userId"
             :token-auth="tokenAuth"
          />
        </b-tab>


        <b-tab @click="EqcoinLoad">

          <template #title>
                      <feather-icon
                                          icon="AwardIcon"
                                          size="15"

                                          class="margin-icon-profile"
                                          />
            <span class=" d-none d-sm-none d-md-block d-lg-block d-xl-block">  EQCoins</span>
            <span class="titulo_menu  d-block d-sm-block d-md-none d-lg-none d-xl-none">EQCoins</span>
          </template>

          <eq-coins
            v-if="ifEqcoins"
            :key="componentKeyEqcoins"
            :user-id="userId"
            :token-auth="tokenAuth"
          />
          </b-tab>

      </b-tabs>

    </b-col>

  </b-row>

</template>

<script>
import {
  BRow, BCard, BForm,  BCol, BButton, VBPopover, BAvatar, BTabs, BTab, BFormFile,BLink
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'
import ModalUserProfile from './ModalUserProfile.vue'
import ModalUserBanner from './ModalUserBanner.vue'
import General from './General.vue'
import Verificacion from './Verificacion.vue'
import EqCoins from './EqCoins.vue'
import Password from './Password.vue'
import DobleAutenticacion from './DobleAutenticacion.vue'
import CuentaBancaria from './CuentaBancaria.vue'
import Tarjetas from './Tarjetas.vue'
import Notificaciones from './Notificaciones.vue'
import PresentacionRedesSociales from './PresentacionRedesSociales.vue'
import AliasPerfil from './AliasPerfil.vue'
import Sesiones from './Sesiones.vue'
import EliminarCuenta from './EliminarCuenta.vue'
import Bot from './Bot.vue'



/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BLink,
    BCol,
    BForm,
    Notificaciones,
    BCard,
    BAvatar,
    BTabs,
    BTab,
    EqCoins,
    BFormFile,
    Password,
    PincodeInput,
    ModalUserBanner,
    ModalUserProfile,
    Bot,
    General,
    Verificacion,
    DobleAutenticacion,
    CuentaBancaria,
    Tarjetas,
    PresentacionRedesSociales,
    AliasPerfil,
    Sesiones,
    EliminarCuenta,
    BButton,
   
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {

    let activeTab=0;
    let ifGeneral=false;
    let ifVerificacion=false;
    let ifPassword=false;
    let ifDobleAutenticacion=false;
    let ifNotificaciones=false;
    let ifBot=false;
    let ifBankAccount=false;
    let ifTarjeta=false;
    let ifPresentacion=false;
    let ifAlias=false;
    let ifSesiones=false;
    let ifEliminar=false;
    let ifEqcoins=false;

           if(window.location.hash) {
      var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

      switch(hash) {
            case "general":
             activeTab=0;
              ifGeneral=true;
           
              break;
            case "verificacion":
              
             activeTab=1;
             ifVerificacion=true;
              
            
              break;

              case "password":
              
              activeTab=2;
              ifPassword=true;
               
             
               break;
            case "pin":  
              activeTab=3;
              ifDobleAutenticacion=true;
            
            
              break;
            case "notificaciones":  
            activeTab=4;

              ifNotificaciones=true;
            
              break;

               case "bot":  
            activeTab=5;

              ifBot=true;
            
              break;

            case "banco":  
            activeTab=6;
            ifBankAccount=true;
           
            
              break;
              case "tarjetas":  
            activeTab=7;
            ifTarjeta=true;
             
            
              break;

                 case "biografia":  
            activeTab=8;
            ifPresentacion=true;
           
            
              break;
             case "alias":  
            activeTab=9;
            ifAlias=true;
              
            
              break;

               case "sesiones":  
            activeTab=10;
            ifSesiones=true;
              
            
              break;
            case "eliminar": 
             activeTab=11; 
             ifEliminar=true;
            
             
              break;

              case "eqcoins": 
             activeTab=12; 
             ifEqcoins=true;
            
             
              break;
           
            default:
               activeTab=0;
               ifGeneral=true;
        
          }

  }else{

    activeTab=0;
    ifGeneral=true;
   

  }

const datos_json = JSON.parse(this.$userGlobal);



    return {
      searchEQcoins:true,
      showModalFoto:false,
      showModalBanner:false,
      componentKeyGeneral: 0,
      componentKeyModalFoto:0,
      componentKeyModalBanner:0,
      componentKeyDobleAu: 0,
      componentKeyNotificaciones: 0,
      componentKeyBot:0,
      componentKeyBankAccount: 0,
      componentKeyEliminar: 0,
      componentKeyEqcoins:0,
      componentKeyTarjeta: 0,
      activoPanel:activeTab,
      componentKeyVerificacion:0,
      componentKeyPassword:0,
      componentKeyPresentacion:0,
      componentKeyAlias: 0,
      componentKeySesiones:0,
      photo:datos_json.photoUser,
      banner:datos_json.bannerUser,
      userId: datos_json.userId,
      isProfileVerificated: datos_json.isProfileVerificated,
      displayName: datos_json.displayName,
      alias: datos_json.alias,
      ifDobleAutenticacion: ifDobleAutenticacion,
      ifNotificaciones: ifNotificaciones,
      ifBot:ifBot,
      ifBankAccount: ifBankAccount,
      ifEliminar: ifEliminar,
      ifEqcoins:ifEqcoins,
      ifTarjeta: ifTarjeta,
      ifGeneral:ifGeneral,
      ifVerificacion:ifVerificacion,
      ifPassword:ifPassword,
      ifPresentacion:ifPresentacion,
      ifAlias: ifAlias,
      ifSesiones:ifSesiones,


      tokenAuth: this.$tokenGlobal,
    }
  },
  created() {

  },watch:{
    $route (to, from){
       
      

      var hash = to.hash.substring(1); //Puts hash in variable, and removes the # character


      switch(hash) {
            case "general":
            this.activoPanel=0;
  
              this.ifGeneral=true;
            
              break;
            case "verificacion":
              
            this.activoPanel=1;
            this.ifVerificacion=true;
              
            
              break;
              case "password":
              
              this.activoPanel=2;
              this.ifPassword=true;
                
              
                break;
            case "pin":  
              this.activoPanel=3;
              this.ifDobleAutenticacion=true;
            
            
              break;
            case "notificaciones":  
            this.activoPanel=4;

              this.ifNotificaciones=true;
            
              break;

              case "bot":  
            this.activoPanel=5;

              this.ifBot=true;
            
              break;

            case "banco":  
            this.activoPanel=6;
            this.ifBankAccount=true;
          
            
              break;
              case "tarjetas":  
            this.activoPanel=7;
            this.ifTarjeta=true;
            
            
              break;
 
                case "biografia":  
            this.activoPanel=8;
            this.ifPresentacion=true;
          
            
              break;
            case "alias":  
            this.activoPanel=9;
            this.ifAlias=true;
              
            
              break;

              case "sesiones":  
            this.activoPanel=10;
            this.ifSesiones=true;
              
            
              break;
            case "eliminar": 
            this.activoPanel=11; 
            this.ifEliminar=true;
            
            
              break;


            case "eqcoins": 
            this.activoPanel=12; 
            this.ifEqcoins=true;
            
            
              break;
          
            default:
              this.activoPanel=0;
              this.ifGeneral=true;
            break;
        
          }
          
   
    }
},
activated() {

      this.$eventBus.$emit('reiniciarMenu1')
       this.$eventBus.$emit('reiniciarMenu2')
      
      
  },


  mounted() {





      this.$eventBus.$on('updateFotoPerfil', (url) => {
      this.photo = url;
    
    })

    
    this.$eventBus.$on('updateBannerPerfil', (url) => {
      this.banner = url;
    
    })


    this.$eventBus.$on('reiniciarModalBannerPerfil', () => {
      this.componentKeyModalBanner += 1
      this.showModalBanner=false;
    })
   
      this.$eventBus.$on('reiniciarModalFotoPerfil', () => {
      this.componentKeyModalFoto += 1
      this.showModalFoto=false;
    })

    document.title = 'EQCoop - Mi Perfil'

    



    this.$eventBus.$on('reiniciarGeneral', () => {
      this.componentKeyGeneral += 1
    })

    this.$eventBus.$on('reiniciarDobleAu', () => {
      this.componentKeyDobleAu += 1
    })

    

      this.$eventBus.$on('reiniciarBot', () => {
      this.componentKeyBot += 1
    })

    this.$eventBus.$on('reiniciarNotificaciones', () => {
      this.componentKeyNotificaciones += 1
    })

    this.$eventBus.$on('reiniciarBankAccount', () => {
      this.componentKeyBankAccount += 1
    })

    

    this.$eventBus.$on('reiniciarEqcoins', () => {
      this.componentKeyEqcoins += 1
    })

    this.$eventBus.$on('reiniciarEliminar', () => {
      this.componentKeyEliminar += 1
    })

    this.$eventBus.$on('reiniciarTarjeta', () => {
      this.componentKeyTarjeta += 1
    })
    

    this.$eventBus.$on('reiniciarPassword', () => {
      this.componentKeyPassword += 1
    })

   this.$eventBus.$on('reiniciarVerificacion', () => {
      this.componentKeyVerificacion += 1
    })


       this.$eventBus.$on('reiniciarPresentacion', () => {
      this.componentKeyPresentacion += 1
    })

  

      this.$eventBus.$on('reiniciarSesiones', () => {
      this.componentKeySesiones += 1
    })

    this.$eventBus.$on('reiniciarAlias', () => {
      this.componentKeyAlias += 1
    })

 
  },
  methods: {



    dobleAutenticacionLoad() {
      this.ifDobleAutenticacion = true
    },

    BotLoad(){

       this.ifBot = true

    },

    NotificacionesLoad() {
      this.ifNotificaciones = true
    },

    BankAccountLoad() {
      this.ifBankAccount = true
    },
    EqcoinLoad(){
      this.ifEqcoins = true
    },
    EliminarLoad() {
      this.ifEliminar = true
    },

    GeneralLoad(){
      this.ifGeneral = true

    },
    PasswordLoad(){
      this.ifPassword = true
    },
VerificacionLoad(){

 this.ifVerificacion = true

},
PresentacionLoad(){
 this.ifPresentacion = true
},


    TarjetasLoad() {
      this.ifTarjeta = true
    },

    SesionesLoad(){
  this.ifSesiones = true
    },

    AliasLoad() {
      this.ifAlias = true
    },

    modalPhoto() {
   
      this.showModalFoto=true;
    },

    modalBanner() {
      this.showModalBanner=true;


    },

  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
