<template>


    <b-card
          v-if="loading"
          align="center">
  
         <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="text-align: center;">
  
                <h4 class="font-weight-bolder">
                        Actualización de contraseña
                    </h4>
                    <hr class="mb-2">




                </b-col>

                <b-col
        cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          > 
          <b-skeleton width="100%"  height="30px"></b-skeleton>

        </b-col>


          <b-col
          cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
          <b-skeleton width="100%"  height="30px"></b-skeleton>

        </b-col>
  
            <b-col
        cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          > </b-col>


          <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          > 

          <b-skeleton width="100%"  height="20px"></b-skeleton>

          <table width="100%">
            <tr>
              <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            </tr>
          </table>

        
        </b-col>

          <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >   </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="30px"></b-skeleton>
        
        </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>
  
        </b-row>
  
          
    </b-card>
  
  
  
         
       <b-card v-else-if="code200">
        <b-form
          autocomplete="off"
          @submit.prevent="onSubmit"
        >

          <b-row>
  
      
  
                <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="text-align: center;">
  
                  <h4 class="font-weight-bolder">
                          Actualización de contraseña
                    </h4>
                    <hr class="mb-2">
  
            
  
  
                </b-col>
  
                <b-col cols="12" sm="12" md="6" lg="6" xl="6" >
                    <p class="textLabel">
                Ingrese su antigua contraseña (*):
              </p>
                    <b-form-group >
                    <b-input-group class="input-group-merge">
                        <b-form-input
                        required
                        v-model="oldPassword"
                        :type="oldPasswordTypeNew"
                        name="new-password"
                        :readonly="isDisabled"
                        placeholder="Ingrese su antigua contraseña"
                        />
                        <b-input-group-append is-text>
                        <feather-icon
                            :icon="oldPasswordToggleIconNew"
                            class="cursor-pointer"
                            @click="toggleOldPassword"
                        />
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>
  
         
  
  
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6" >
                    <p class="textLabel">
                Ingrese su nueva contraseña (*):
              </p>
                    <b-form-group >
                    <b-input-group class="input-group-merge">
                        <b-form-input
                        required
                        v-model="newPassword"
                        :type="newPasswordTypeNew"
                        @input="password_check"
                      @paste.prevent
                        name="new-password"
                        :readonly="isDisabled"
                        placeholder="Ingrese su nueva contraseña"
                        />
                        <b-input-group-append is-text>
                        <feather-icon
                            :icon="newPasswordToggleIconNew"
                            class="cursor-pointer"
                            @click="toggleNewPassword"
                        />
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>          
                
                
                </b-col>

                <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px">
                        <b-alert
                              variant="primary"
                              show
                            >
                              <div class="alert-body">
                                <p  class="textLabel" style="font-weight: bold;margin-bottom: 10px;">
                                Su nueva contraseña debe cumplir con los siguientes requisitos:
                              </p>
                               
                                <p class="frmValidation" :class="{'frmValidation--passed' : has_minLongitud == true}">

                                  <feather-icon
                                    :icon="icon1"
                                    size="21"
                                    :class="has_minLongitud == true ? 'color_green' : 'color_red'"
                                  />


                                  Debe tener una longitud mínima de 9 caracteres.
                                  </p>
                                  <p class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }">
                                  <feather-icon
                                    :icon="icon2"
                                    size="21"
                                    :class="has_uppercase == true ? 'color_green' : 'color_red'"
                                  /> Debe tener al menos una letra en mayúscula.</p>
                                  <p class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }">
                                  <feather-icon
                                    :icon="icon3"
                                    size="21"
                                    :class="has_lowercase == true ? 'color_green' : 'color_red'"
                                  /> Debe tener al menos una letra en minúscula.</p>
                                  <p class="frmValidation" :class="{'frmValidation--passed' : has_number }">
                                  <feather-icon
                                    :icon="icon4"
                                    size="21"
                                    :class="has_number == true ? 'color_green' : 'color_red'"
                                  />
                                  Debe tener al menos un número.</p>
                                  <p class="frmValidation" :class="{'frmValidation--passed' : has_special }">

                                  <feather-icon
                                    :icon="icon5"
                                    size="21"
                                    :class="has_special == true ? 'color_green' : 'color_red'"
                                  />
                                  Debe tener al menos un caracter especial.</p>

                              </div>
                            </b-alert>
                      </b-col>

                <b-col
                    sm="12"
                    md="12"
                    lg="3"
                    xl="3"
                    ></b-col>

                    <b-col
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                    >

                    <p
                        class="textLabel"
                        style="text-align: center;margin-bottom: 10px;"
                    >
                        Ingrese su código PIN (*): <feather-icon
                        icon="HelpCircleIcon"
                        size="16"
                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                        title="Código PIN de seguridad"
                        style="margin-right: 5px;cursor:pointer;"
                        /> </p>

                    <PincodeInput
                        v-model="pin"
                        required
                        placeholder="-"
                        :length="6"
                        :autofocus="false"
                        :secure="true"
                        :readonly="isDisabled"
                    />

                    </b-col>

                    <b-col
                    sm="12"
                    md="12"
                    lg="3"
                    xl="3"
                    ></b-col>

                    <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        align="center"
                        style="margin-top:10px; margin-bottom: 10px;"
                        >

                        <b-button
                            id="btn_actualizar"
                            ref="btn_actualizar"
                            :disabled="isDisabled2"
                            variant="primary"
                            type="submit"
                        >

                            Actualizar contraseña
                        </b-button>
                        </b-col>
               
               
  
             
  
                </b-row>
  
             </b-form> 
  
    </b-card>
  
  
  
          <b-card align="center"  v-else>

            <b-row>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="text-align: center;">
                
                <h4 class="font-weight-bolder">
                        Actualización de contraseña
                    </h4>
                    <hr class="mb-2">




                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="text-align: center;">
                    <img style="width: 100px;margin-bottom: 15px;" src="/img/error.svg"/>
    
                      <p style="text-align: center;font-size:14px; font-weight: bold;">Esta funcionalidad solo está disponible para los usuarios que inician sesión con SMS</p>

   

                    </b-col>

            </b-row>

       
          
  
            </b-card>
  
  
  </template>
  
  <script>
  import {
    BButton,BSkeleton,VBPopover, BRow, BCol, BCard, BAlert, BAvatar, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,BForm
  } from 'bootstrap-vue'
  import PincodeInput from 'vue-pincode-input'
  import Vue from 'vue'
  export default {
    components: {
      BButton,
      PincodeInput,
      BAvatar,
      BAlert,
      BSkeleton,
      BRow,
      BCol,
      BCard,
      BForm,
      BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
    },
    directives: {
    'b-popover': VBPopover,
  },
    props: ['userId', 'tokenAuth'],
    data() {
  
  
  
      return {
        loading: true,
        code200:false,
        isDisabled: false,
        isDisabled2:false,
        pin:"",
        oldPassword:"",
        newPassword:"",
        oldPasswordTypeNew: 'password',
        newPasswordTypeNew: 'password',
        has_number:    false,
                  has_lowercase: false,
                  has_uppercase: false,
                  has_special:   false,
                  has_minLongitud:   false,
                  icon1: "XCircleIcon",
                  icon2: "XCircleIcon",
                  icon3: "XCircleIcon",
                  icon4: "XCircleIcon",
                  icon5: "XCircleIcon",
      }
    },
    computed: {

        oldPasswordToggleIconNew() {
              return this.oldPasswordTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },

            newPasswordToggleIconNew() {
              return this.newPasswordTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
  
    },
    watch: {
  
  
  
    },
    mounted() {

        this.checkHasPassword();
  
   
  
  
    },
    methods: {

        password_check(value){

            if(Number(value.length) >= Number("9")){
            this.has_minLongitud=true;
            this.icon1="CheckCircleIcon"
            }else{
            this.has_minLongitud=false;
            this.icon1="XCircleIcon"
            }
            this.has_number    = /\d/.test(this.newPassword);
            if(this.has_number){
            this.icon4="CheckCircleIcon"
            }else{
            this.icon4="XCircleIcon"
            }
            this.has_lowercase = /[a-z]/.test(this.newPassword);
            if(this.has_lowercase){
            this.icon3="CheckCircleIcon"
            }else{
            this.icon3="XCircleIcon"
            }
            this.has_uppercase = /[A-Z]/.test(this.newPassword);
            if(this.has_uppercase){
            this.icon2="CheckCircleIcon"
            }else{
            this.icon2="XCircleIcon"
            }
            this.has_special   = /[!@#\$%\^\&*\)\(+=._-]/.test(this.newPassword);
            if(this.has_special){
            this.icon5="CheckCircleIcon"
            }else{
            this.icon5="XCircleIcon"
            }

            if(this.has_minLongitud &&  this.has_number &&  this.has_lowercase &&  this.has_uppercase &&  this.has_special ){ 
            this.isDisabled2= false;

            }else{
            this.isDisabled2= true;
            }
            },
    onSubmit(event) {
      event.preventDefault()

      const data_json = { userId: this.userId, pin: this.pin, oldPassword: this.oldPassword, newPassword: this.newPassword }
      const data_string = JSON.stringify(data_json)

    
      const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

      this.isDisabled = true
      this.isDisabled2 = true
      document.getElementById('btn_actualizar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando'

      this.$https.post('/profile/updatePassword/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        document.getElementById('btn_actualizar').innerHTML = 'Actualizar contraseña'
        this.isDisabled = false
        this.isDisabled2 = false
        if (response.data.code == 200) {

          this.oldPassword="";
          this.newPassword="";
          this.pin="";
          
            this.has_minLongitud=false;
            this.has_number=false;
            this.has_lowercase=false;
            this.has_special=false;
            this.has_uppercase=false;
     
          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

  
  

        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },


        toggleOldPassword() {
      this.oldPasswordTypeNew = this.oldPasswordTypeNew === 'password' ? 'text' : 'password'
    },

    toggleNewPassword() {
      this.newPasswordTypeNew = this.newPasswordTypeNew === 'password' ? 'text' : 'password'
    },
  
        checkHasPassword(){


  
      const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)
  
      this.$https.post('/profile/checkHasPassword/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
        if (response.data.code == 200) {
          this.loading = false
           this.code200 = true

         
        } else {
     
  
          if (response.data.code == 401) {

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

            
            localStorage.removeItem('userData')
            
            
  

  
            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else {
           
            if (response.data.code == 800) {
                this.loading=false;
                this.code200 = false
            }else{
                if (response.data.code == 801) {

                  const datos_json = JSON.parse(this.$userGlobal);

                    datos_json.hasPassword = false


                    Vue.prototype.$userGlobal= JSON.stringify(datos_json);

                    localStorage.setItem('userData', JSON.stringify(datos_json));


                    this.$router.push('/password')

                }else{
                    this.checkHasPassword();
                }
            }
          }
        }
      }).catch(error => {
        
        this.checkHasPassword();
      })

        }
  
  
    },
  }
  </script>
  